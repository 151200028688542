@if(!(isLoading$ | async)) {
<div [className]="'asnt-main-visual-inner ' + deviceInfo +' '+ (pageType$ | async)">
    <div class="asnt-main-visual-mask"></div>
    <div class="asnt-main-visual-image">

        <img [src]="getImageSrc() | async" [alt]="pageTitle_EN$ | async" loading="eager" />

    </div>

    <div class="asnt-main-visual-content">

        @if (!noText) {
        <div class="asnt-main-visual-pageTitle">{{pageTitle_CH$ | async}}</div>
        <div class="asnt-main-visual-pageTitle_EN">
            <div class="asnt-main-visual-pageTitle_EN-text">{{pageTitle_EN$ | async}}</div>
        </div>
        <ng-content select=[element]></ng-content>
        } @else {
        <ng-content></ng-content>
        }

    </div>

    <asnt-breadcrumb [className]="deviceInfo"></asnt-breadcrumb>
</div>
}