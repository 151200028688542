<main-container [useTopFixedElem]="true"
    [ngClass]="{'mobile': deviceInfo === 'mobile', 'tablet': deviceInfo === 'tablet', 'desktop': deviceInfo === 'desktop'}">
    <ul class="asnt-breadcrumb-ul">
        <li><a href="/">首頁</a></li>

        <ng-container *ngIf="breadcrumbClassA$ | async as breadcrumbClassA">
            <li *ngIf="breadcrumbClassA[0] != ''">
                @if (breadcrumbClassA[1] != '') {
                <a [routerLink]="breadcrumbClassA[1]">
                    {{ breadcrumbClassA[0] }}
                </a>
                } @else {
                <span class="asnt-breadcrumb-span">{{ breadcrumbClassA[0] }}</span>
                }
            </li>
        </ng-container>

        <ng-container *ngIf="breadcrumbClassB$ | async as breadcrumbClassB">
            <li *ngIf="breadcrumbClassB[0] != ''">
                @if (breadcrumbClassB[1] != '') {
                <a [routerLink]="breadcrumbClassB[1]">
                    {{ breadcrumbClassB[0] }}
                </a>
                } @else {
                <span class="asnt-breadcrumb-span">{{ breadcrumbClassB[0] }}</span>
                }
            </li>
        </ng-container>

        <ng-container *ngIf="breadcrumbClassC$ | async as breadcrumbClassC">
            <li *ngIf="breadcrumbClassC[0] != ''">
                @if (breadcrumbClassC[1] != '') {
                <a [routerLink]="breadcrumbClassC[1]">
                    {{ breadcrumbClassC[0] }}
                </a>
                } @else {
                <span class="asnt-breadcrumb-span">{{ breadcrumbClassC[0] }}</span>
                }
            </li>
        </ng-container>




    </ul>
</main-container>