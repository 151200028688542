import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ResourceLoaderService {

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) { }

  loadResource(rel: string, href: string, crossOrigin: boolean = false) {
    const link = this.document.createElement('link');
    link.rel = rel;
    link.href = href;
    if (crossOrigin) {
      link.crossOrigin = '';
    }
    this.document.head.appendChild(link);
  }
}
