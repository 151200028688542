import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AsntBreadcrumbComponent } from './asnt-breadcrumb.component';
import { RouterLink } from '@angular/router';
import { PageContactModule } from '../../page/page-contact/page-contact.module';
import { MainContainerComponent } from '../asnt-content/main-container/main-container.component';



@NgModule({
  declarations: [
    AsntBreadcrumbComponent,
  ],
  imports: [
    CommonModule,
    RouterLink,
    MainContainerComponent,
  ],
  exports: [
    AsntBreadcrumbComponent,
  ]
})
export class AsntBreadcrumbModule { }
