import { Component, HostBinding, Input } from '@angular/core';
import { Observable, map } from 'rxjs';
import { imgPath } from '../../app-path';
import { PageInfoService } from '../../service/page-info.service';
import { ResourceLoaderService } from '../../service/resource-loader.service';
import { CommonModule } from '@angular/common';
import { AsntBreadcrumbModule } from '../asnt-breadcrumb/asnt-breadcrumb.module';
import { CustomDeviceDetectService } from '../../service/custom-device-detect.service';
import { LoadTimerService } from '../../service/load-timer.service';


@Component({
  standalone: true,
  selector: 'asnt-main-visual',
  templateUrl: './asnt-main-visual.component.html',
  styleUrl: './asnt-main-visual.component.less',
  imports: [
    AsntBreadcrumbModule,
    CommonModule
  ]
})
export class AsntMainVisualComponent {

  pageType$!: Observable<string>;
  pageKeyVisual$!: Observable<string>;
  pageTitle_CH$!: Observable<string>;
  pageTitle_EN$!: Observable<string>;
  isLoading$!: Observable<boolean>;

  @Input() noText: boolean = false;
  @Input() loginPanel: boolean = false;

  // 圖檔路徑
  imgPath_desktop = imgPath.mainVisual_desktop;
  deviceInfo: string = 'Unknown';
  private pageKeyVisual: string = '';


  @HostBinding('class') get hostClasses(): string {
    return `asnt-main-visual ${this.pageKeyVisual}`;
  }

  constructor(
    private pageInfoService: PageInfoService,
    private resourceLoader: ResourceLoaderService,
    private loadTimerService: LoadTimerService,
    private customDeviceService: CustomDeviceDetectService,
  ) { }

  ngOnInit() {

    this.isLoading$ = this.loadTimerService.getMainLoadStatus();
    this.pageKeyVisual$ = this.pageInfoService.getPageKeyVisual();
    this.pageTitle_CH$ = this.pageInfoService.getPageTitle_CH();
    this.pageTitle_EN$ = this.pageInfoService.getPageTitle_EN();
    this.pageType$ = this.pageInfoService.getPageType();
    this.pageKeyVisual$.subscribe(name => this.pageKeyVisual = name);
    // 同步設備類型的值
    this.customDeviceService.deviceInfo$.subscribe((type) => {
      this.deviceInfo = type;
    });

    setTimeout(() => {
      this.resourceLoader.loadResource('preconnect', 'https://fonts.gstatic.com', true);
      this.resourceLoader.loadResource('dns-prefetch', 'https://fonts.gstatic.com');
      this.resourceLoader.loadResource('stylesheet', 'https://fonts.googleapis.com/css2?family=Noto+Serif+TC:wght@500&display=swap');
      this.resourceLoader.loadResource('stylesheet', 'https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,300,0,0');
    }, 2400);
  }

  getImageSrc(): Observable<string> {
    return this.pageKeyVisual$.pipe(
      map((kv) => `${this.imgPath_desktop}${kv}.jpg`)
    );
  }



}
