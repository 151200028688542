import { Component, HostBinding } from '@angular/core';
import { Observable } from 'rxjs';
import { PageInfoService } from '../../service/page-info.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CustomDeviceDetectService } from '../../service/custom-device-detect.service';


@Component({
  selector: 'asnt-breadcrumb',
  templateUrl: './asnt-breadcrumb.component.html',
  styleUrl: './asnt-breadcrumb.component.less'
})
export class AsntBreadcrumbComponent {

  // deviceInfo: string = this.deviceService.getDeviceInfo().deviceType;
  deviceInfo: string = 'Unknown';
  breadcrumbClassA$!: Observable<[string, string]>;
  breadcrumbClassB$!: Observable<[string, string]>;
  breadcrumbClassC$!: Observable<[string, string]>;

  @HostBinding('class') get hostClasses(): string {
    return `asnt-breadcrumb ${this.deviceInfo}`;
  }

  constructor(
    // private deviceService: DeviceDetectorService,
    private customDeviceService: CustomDeviceDetectService,
    private pageInfoService: PageInfoService,
  ) { }

  ngOnInit() {
    this.breadcrumbClassA$ = this.pageInfoService.get_breadcrumbClassA();
    this.breadcrumbClassB$ = this.pageInfoService.get_breadcrumbClassB();
    this.breadcrumbClassC$ = this.pageInfoService.get_breadcrumbClassC();
    // 同步設備類型的值
    this.customDeviceService.deviceInfo$.subscribe((type) => {
      this.deviceInfo = type;
    });
  }
}
